import * as React from "react";
import ExchangeBlock from "./ExchangeBlock";
import AvailableInCountry from "./AvailableInCountry";

export const ExchangeContent = () =>
    <div className="exchange__content">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 light">
                    <div className="exchange__text">
                        <h1 className="exchange__title title">Buy Bitcoins <br /> Fast With Credit Cards And Bank Transfer
                        </h1>
                        <p className="exchange__slogan slogan">Get the access to the BTC markets in a couple of seconds
                            with absolutely zero delay!</p>
                        <div className="d-lg-inline-block d-none">
                            <AvailableInCountry />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <ExchangeBlock />
                    <div className="exchange-respons d-flex align-items-center float-lg-right">
                        <img src="img/user.jpg" alt="user" className="exchange-respons__img"/>
                        <span className="exchange-respons__quote">“One of the best cryptocurrency exchanges and rate your CS service very highly.”</span>
                    </div>
                    <div className="d-lg-none d-inline-block">
                        <AvailableInCountry />
                    </div>
                </div>
            </div>
        </div>
    </div>
