import * as React from "react";

export const BuyInfoBlock = () => {
    return (
        <section className="how">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="how__title title text-sm-center text-left">3 Steps to buy Bitcoins - easy as a pie:</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 order-lg-0 order-0">
                        <div className="how__box">
                            <div className="how__icon">
                                <img src="img/home_03_01.svg" alt="home" className="img-fluid how__icon_img" />
                            </div>
                            <h3 className="how__box_title">Choose the currency and select the amount you need to exchange</h3>
                            <p className="how__description">Fill in the corresponding fields with the fiat currency and the sum of BTC you would like to buy. The indicated Bitcoin amount will be converted into your fiat currency automatically by the system.</p>
                        </div>
                        <div className="how__box">
                            <div className="how__icon">
                                <img src="img/home_03_02.svg" alt="home" className="img-fluid how__icon_img" />
                            </div>
                            <h3 className="how__box_title">Fill in your personal info and verify it</h3>
                            <p className="how__description">Do not worry, you will need to provide just the minimum amount of data. Everything is safe and secure. After that you can enjoy all the benefits of working with us. Using our service means an easy, quick and absolutely safe access to the crypto world’s lowest BTC prices.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center how__bg_dots order-lg-1 order-2">
                        <img src="img/home_mobile_app.png" alt="home_mobile_app" className="img-fluid" />
                        <div className="how__info d-lg-none d-block">
                            <a  href="exchange" className="button button_bg_red how__link_but">Buy coins instantly</a>
                            <span className="how__link_description">New to Cryptocurrency? </span>
                            <a href="faq.html" className="how__link">Learning Center</a>
                        </div>
                    </div>
                    <div className="col-lg-3 order-lg-2 order-1">
                        <div className="how__box">
                            <div className="how__icon">
                                <img src="img/home_03_03.svg" alt="home" className="img-fluid how__icon_img" />
                            </div>
                            <h3 className="how__box_title">Confirm the transaction and get the Bitcoins</h3>
                            <p className="how__description">The transaction is processed immediately as soon as you have verified your personal information. The coins will also appear on the chosen wallet at once. You can choose your credit card account, bank account or Gempal account directly.</p>
                        </div>
                        <div className="how__info d-lg-block d-none">
                            <a href="exchange" className="button button_bg_red how__link_but">Buy coins instantly</a>
                            <span className="how__link_description">New to Cryptocurrency?  Visit our <br></br></span>
                            <a href="faq.html" className="how__link">Learning Center</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}