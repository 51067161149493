import * as React from "react";
import OptionsApiData from "../common/OptionsApiData";
import {ExchangeRate} from "./ExchangeRate";
import {Component} from "react";
import {ApiData} from "../../models/ApiData";

export default class ExchangeCryptoRates extends Component {
    state = {
        data_list: [{}],
        selected_symbol: "EUR",
        loaded: false
    }
    public onChangeCurrency = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var title = event.target.selectedOptions[0].text;
        this.loadSymbol(title);
    }

    public componentDidMount(): void {
        this.loadSymbol("EUR");
    };

    private loadSymbol(symbol:string)
    {
        ApiData.exchangeApiRequest({symbol: symbol}, 'get_rates_fiat_symbol').then((result) => {
            if(result.success)
                this.setState({
                    data_list: result.data,
                    selected_symbol: symbol,
                    loaded: true
                });
        });
    }



    public render = () => {
        return <>
            <div className="assets__crypto box__crypto box light">
                <div className="box__header d-md-flex align-items-center justify-content-between">
                    <span className="box__title">Supported Crypto Currencies</span>
                    <div className="price__currency float-lg-right">
                        <select name="#" id="#" className="box__select select_dark" onChange={this.onChangeCurrency} value={this.state.selected_symbol}>
                            <OptionsApiData type={'fiat'}  key_equals_value={true}/>
                        </select>
                    </div>
                </div>
                {this.state.loaded &&

                <div className="box__wrapper box__scroll mCustomScrollbar" data-mcs-theme="light">
                    {this.state.data_list.filter((data: any) => data.symbol!=="BCH").map((e: any, index:number) =>  <ExchangeRate key={index} from_cur={e.symbol ? e.symbol : ''} from_cur_title={e.title} to_cur={this.state.selected_symbol} rate={e.rate}/>)}
                </div>
                }
            </div>
        </>
    }
}
