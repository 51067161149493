import {Component, default as React} from "react";
import useForm from 'react-hook-form'
import {useHistory} from "react-router-dom";
import OptionsApiData from "../common/OptionsApiData";
import {ApiData, ExceptionCodes, getApiDataPromise} from "../../models/ApiData";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";

export default function AffiliateSignUp()
{
    const { register, handleSubmit, watch, errors } = useForm();
    const history = useHistory();
    let in_progress = false;

    const onSubmit = async function (data: any) {

        if(in_progress)
            return;

        in_progress = true;

        data['g-recaptcha-response'] = $('[name="g-recaptcha-response"]').val();

        if(!data['g-recaptcha-response']) {
            toast.error("Please fill out the reCAPTCHA!");
            return;
        }

        var result = await getApiDataPromise(data,"public_data", "send_affiliate_mail");

        if(result.success) {
            toast.success("Successful!");
            $("#aff_form").hide();
        }
        else {
            toast.error(result.message);
            in_progress = false;
        }
    }


    const onCountriesLoaded = () =>
    {
        ApiData.clientApiRequest({}, "get_country").then((data) => {
            if(data.success)
                $("#country_code").val(data.data['code']);
        });
    }

    return (
        <>
            <section className="signup" id="signup">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 light">
                            <h2 className="signup__title title">Sign up now & start earning with us!</h2>
                        </div>
                        <div className="col-lg-7 text-lg-right text-center">
                            <form onSubmit={handleSubmit(onSubmit)} id={"aff_form"}>
                                <div className="signup__form exchange-form box text-left">
                                    <div className="signup__form_boxing">
                                        <h3 className="steps__box_title">Contact information</h3>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form__item">
                                                    <input name="first_name" type="text" className="input input_small"
                                                           placeholder="First Name" ref={register({ required: true, minLength: 2 })}/>
                                                    {errors.first_name && <span className="erorrs">This field is required!</span>}

                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form__item">
                                                    <input name="last_name" type="text" className="input input_small"
                                                           placeholder="Last Name" ref={register({ required: true, minLength: 2 })}/>
                                                    {errors.last_name && <span className="erorrs">This field is required!</span>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form__item">
                                                    <input name="email" type="text" className="input input_small"
                                                           placeholder="Email (used for communication)"  ref={register({ required: true, pattern: /^\S+@\S+$/i })} />
                                                    {errors.email && <span className="erorrs">Please enter a valid email!</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form__item">
                                                    <input name="skype" type="text" className="input input_small"  placeholder="Skype"  ref={register({required: false})} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form__item form__item_nolabel">
                                                    <select id="country_code" name="country_code" className="input input_small" ref={register({ required: true })} >
                                                        <OptionsApiData type={"country"} key_equals_value={false} onLoaded={onCountriesLoaded}/>
                                                    </select>

                                                    {errors.country && <span className="erorrs">This field is required!</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form__item">
                                                    <input name="phone"  type="tel" className="input input_small" placeholder="Phone" ref={register({required: true,  pattern: /^[0-9\+]{1,}[0-9\-]{5,15}$/})} />
                                                    {errors.phone && <span className="erorrs">Please enter a valid phone!</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="steps__box_title">Tell Us about your traffic</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form__item_modal">
                                                <input name="website" type="url" className="input input_small" placeholder="Website URL" ref={register({ required: true })} />
                                                {errors.website && <span className="erorrs">Please enter a valid url, like: http://site.com/</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form__item_modal">
                                                <input name="visitors_count" type="text" className="input input_small" placeholder="Current monthly unique visitors" ref={register({ required: true })} />
                                                {errors.visitors_count && <span className="erorrs">This field is required!</span>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="recapcha">
                                                <ReCAPTCHA sitekey="6Lct3RgqAAAAAKbFasPzUhv935OyPnZJ0F8KkstV"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="steps__form_footer">
                                                <button type="submit" className="button button_bg_red">Send</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}