import * as React from "react";

export interface ExchangeRateProps {
  from_cur: string;
  from_cur_title: string;
  from_rate?: number;
  rate: number;
  to_cur: string;

  hide_from_cur?: boolean;
}

export class ExchangeRate extends React.Component<ExchangeRateProps, {}> {
  getCurrencyIcon = (currency: string) => {
    if (currency.startsWith("USDT")) return "usdt";
    if (currency.startsWith("USDC")) return "usdc";
    return currency.toLowerCase();
  };
  render() {
    return (
      <div className="box-row d-flex">
        <div className="box-row__cur d-flex" style={{ width: "calc(70% - 30px)" }}>
          <img
            src={"img/currency/" + this.getCurrencyIcon(this.props.from_cur) + "-w.svg"}
            alt={this.props.from_cur.toLowerCase()}
            className="assets-row__img"
          />
          <div>
            <span className="box-row__summ" style={{ fontSize: 24 }}>
              {this.props.from_rate ? this.props.from_rate : 1} {this.props.hide_from_cur ? "" : this.props.from_cur.toUpperCase()}
            </span>
            <span className="box-row__name">{this.props.from_cur_title}</span>
          </div>
        </div>
        <div className="box-row__icon">
          <img src="img/change.svg" alt="change" className="box-row__icon_change" />
        </div>
        <div className="box-row__cur" style={{ flexShrink: 2 }}>
          <span className="box-row__summ">{this.props.rate}</span>
          <span className="box-row__name">{this.props.to_cur.toUpperCase()}</span>
          <span className="box-row__name">{this.props.from_cur_title}</span>
        </div>
      </div>
    );
  }
}
