import * as React from "react";

export const AffiliateContent = () => {
  return (
    <>
      <section className="make">
        <div className="container make__bg">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title title__make">Simply make money with us!</h2>
              <a href="#signup" className="button button_bg_red main-aff__butt">
                join now
              </a>
            </div>
            <div className="col-lg-3">
              <div className="make__box">
                <div className="make__box_top make__color_1 light">
                  <h3 className="make__box_title">level Basic</h3>
                  <span className="make__box_val">3%</span>
                  <span className="make__box_remark">Gain on exchange</span>
                </div>
                <div className="make__box_bottom">
                  <span className="make__box_sum">$0</span>
                  <span className="make__box_des">Required earnings</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="make__box">
                <div className="make__box_top make__color_2 light">
                  <h3 className="make__box_title">level Junior</h3>
                  <span className="make__box_val">5%</span>
                  <span className="make__box_remark">Gain on exchange</span>
                </div>
                <div className="make__box_bottom">
                  <span className="make__box_sum">$100</span>
                  <span className="make__box_des">Required earnings</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="make__box">
                <div className="make__box_top make__color_3 light">
                  <h3 className="make__box_title">level Medium</h3>
                  <span className="make__box_val">10%</span>
                  <span className="make__box_remark">Gain on exchange</span>
                </div>
                <div className="make__box_bottom">
                  <span className="make__box_sum">$500</span>
                  <span className="make__box_des">Required earnings</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="make__box">
                <div className="make__box_top make__color_4 light">
                  <h3 className="make__box_title">level High</h3>
                  <span className="make__box_val">12%</span>
                  <span className="make__box_remark">Gain on exchange</span>
                </div>
                <div className="make__box_bottom">
                  <span className="make__box_sum">$1000</span>
                  <span className="make__box_des">Required earnings</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="make__box">
                <div className="make__box_top make__color_5 light">
                  <h3 className="make__box_title">level Premium</h3>
                  <span className="make__box_val">15%</span>
                  <span className="make__box_remark">Gain on exchange</span>
                </div>
                <div className="make__box_bottom">
                  <span className="make__box_sum">$5000</span>
                  <span className="make__box_des">Required earnings</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="make__box">
                <div className="make__box_top make__color_6 light">
                  <h3 className="make__box_title">level VIP</h3>
                  <span className="make__box_val">20%</span>
                  <span className="make__box_remark">Gain on exchange</span>
                </div>
                <div className="make__box_bottom">
                  <span className="make__box_sum">$10000</span>
                  <span className="make__box_des">Required earnings</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ask">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-center ask__title title">
                Frequently Asked Questions
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ask__panel">
                <div className="ask__heading">
                  <h4 className="ask__panel_title">
                    <a
                      data-toggle="collapse"
                      href="#collapse1"
                      className="ask__link"
                      aria-expanded="true"
                    >
                      How often do I get paid?
                    </a>
                  </h4>
                </div>
                <div id="collapse1" className="ask__collapse collapse show">
                  <div className="ask__body">
                    <p className="ask__text">
                      When you reach minimum payout of $200 we provide you with
                      bi-weekly payments options. You can find more details in
                      Payout section. Feel free to look through and in case you
                      have any other questions you can always contact us.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ask__panel">
                <div className="ask__heading">
                  <h4 className="ask__panel_title">
                    <a
                      data-toggle="collapse"
                      href="#collapse3"
                      className="ask__link"
                    >
                      How do I get paid?
                    </a>
                  </h4>
                </div>
                <div id="collapse3" className="ask__collapse collapse">
                  <div className="ask__body">
                    <p className="ask__text">
                      There are two options – wire transfer or BTC.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ask__panel">
                <div className="ask__heading">
                  <h4 className="ask__panel_title">
                    <a
                      data-toggle="collapse"
                      href="#collapse4"
                      className="ask__link"
                    >
                      WHAT LINKS AND LANDING PAGES PROVIDE THE BEST PERFORMANCE?
                    </a>
                  </h4>
                </div>
                <div id="collapse4" className="ask__collapse collapse">
                  <div className="ask__body">
                    <p className="ask__text">
                      We have been working hard to provide you with the best
                      performance-driving creatives of different sorts. Please
                      take a moment to check our Monetization Solutions Section
                      to find out which LP and link are the best options for
                      you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ask__panel">
                <div className="ask__heading">
                  <h4 className="ask__panel_title">
                    <a
                      data-toggle="collapse"
                      href="#collapse5"
                      className="ask__link"
                    >
                      I LOVE MY DASHBOARD! CAN I TRACK MY CONVERSIONS FROM
                      THERE?
                    </a>
                  </h4>
                </div>
                <div id="collapse5" className="ask__collapse collapse">
                  <div className="ask__body">
                    <p className="ask__text">
                      Sure you can! Gempal’s tracking system is completely
                      transparent, simplified but enough comprehensive to cover
                      all your needs. You can return to your dashboard and find
                      there a huge variety of tracking statistical data tools
                      that we have collected and made available specially for
                      you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ask__panel">
                <div className="ask__heading">
                  <h4 className="ask__panel_title">
                    <a
                      data-toggle="collapse"
                      href="#collapse6"
                      className="ask__link"
                    >
                      Do I earn commission on every purchase?{" "}
                    </a>
                  </h4>
                </div>
                <div id="collapse6" className="ask__collapse collapse">
                  <div className="ask__body">
                    <p className="ask__text">
                      As far you are considered to be a Gempal affiliate you
                      never miss a click. Payout section provides profound
                      answers to this and other questions. Look through it for
                      more details regarding payout options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="join">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title title__join">
                Why to join the Gempal affiliate program
              </h2>
            </div>
            <div className="col-lg-6">
              <img
                src="img/about_04.svg"
                alt="dots"
                className="join__dots img-fluid"
              />
              <div className="row">
                <div className="col-lg-6">
                  <div className="join__item">
                    <img
                      src="img/affiliate/icons/04/01.svg"
                      alt="icons"
                      className="join__item_ic"
                    />
                    <h3 className="join__item_title">
                      PAYOUTS IN BI-WEEKLY BASIS
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="join__item">
                    <img
                      src="img/affiliate/icons/04/02.svg"
                      alt="icons"
                      className="join__item_ic"
                    />
                    <h3 className="join__item_title">FORGET ABOUT WIRE FEES</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="join__item">
                <img
                  src="img/affiliate/icons/04/03.svg"
                  alt="icons"
                  className="join__item_ic"
                />
                <h3 className="join__item_title">WORLDWIDE TRAFFIC</h3>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="join__item">
                <img
                  src="img/affiliate/icons/04/04.svg"
                  alt="icons"
                  className="join__item_ic"
                />
                <h3 className="join__item_title">
                  2-TIER AFFILIATE REFERRAL PROGRAM
                </h3>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="join__item">
                <img
                  src="img/affiliate/icons/04/05.svg"
                  alt="icons"
                  className="join__item_ic"
                />
                <h3 className="join__item_title">
                  USER-FRIENDLY ACCOUNT SUPPORT. <br />
                  Email AND SKYPE TO YOUR SERVICE
                </h3>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="join__item">
                <img
                  src="img/affiliate/icons/04/06.svg"
                  alt="icons"
                  className="join__item_ic"
                />
                <h3 className="join__item_title">
                  TOP PERFORMING AFFILIATES GET MORE ADVANTAGES AND LESS
                  COMISSION
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="solutions light">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title title__solutions text-center">
                Exceptional <br />
                Monetization Solutions
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="solutions__item text-center">
                <img
                  src="img/affiliate/photos/photo_1@2x.png"
                  alt="icon"
                  className="solutions__ic"
                />
                <div className="solutions__title">Tracking is real time</div>
                <div className="solutions__des">
                  We have chosen transparency to be our moto so we made it easy
                  for you to keep track of your traffic. Conversations can also
                  be tracked in real time. You are given sophisticated and
                  detailed tools for monitoring the statistics, CR, CTR. eCPC
                  monitoring is included as well. The state-of-the-art system is
                  user friendly and customized properly so it meets all your
                  needs, so you can even track system pixel integration.
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="solutions__item active solutions__item_center text-center">
                <img
                  src="img/affiliate/photos/photo_2@2x.png"
                  alt="icon"
                  className="solutions__ic"
                />
                <div className="solutions__title">
                  PERFORMANCE-PROVEN CREATIVES
                </div>
                <div className="solutions__des">
                  Another core principle for us is simplicity. There is a range
                  of banners, logos and landing pages easy to work with. It is
                  enough just to do the copy-paste job for good result. A lot of
                  other creative tools are in hand at your disposal. Our goal is
                  to provide you with an opportunity to focus on your campaigns,
                  not on fitting the creatives to your needs, loosing precious
                  time and substantial amounts of earned money. All our best
                  creatives are customizable and come in various shapes from the
                  very beginning. You can simply provide us with the list of
                  your needs and we will do our best so that you will receive
                  what you have requested. We promise, you will get the most out
                  of traffic.
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="solutions__item text-center">
                <img
                  src="img/affiliate/photos/photo_3@2x.png"
                  alt="icon"
                  className="solutions__ic"
                />
                <div className="solutions__title">
                  SPECTACULAR LPS AND EMAIL CREATIVES
                </div>
                <div className="solutions__des">
                  You can download all the tools to work with including not just
                  emails with a plane text but html email templates as well as
                  complete landing pages ready to work. Users keep registering
                  and working with our company because we are always ahead of
                  the leading trends in the industry as progress is our bread
                  and butter. Working with us you will get the best options
                  along as thousands of our customers.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cryptocurrency-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="title title__cryptocurrency-faq">
                Welcome to affiliate with Gempal
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="box box__currencies cryptocurrency-faq__box light text-center">
                <img
                  src="img/affiliate/icons/06/01.svg"
                  alt="img"
                  className="cryptocurrency-faq__ic"
                />
                <h3 className="cryptocurrency-faq__title">
                  Want To <br />
                  Earn?
                </h3>
                <a href="#signup" className="button button_bg_red">
                  join now
                </a>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="box box__crypto light text-center">
                <img
                  src="img/affiliate/icons/06/02.svg"
                  alt="img"
                  className="cryptocurrency-faq__ic"
                />
                <h3 className="cryptocurrency-faq__title">
                  Questions <br />
                  Appeared?
                </h3>
                <p className="cryptocurrency-faq__text">
                  Don’t hesitate to contact us by writing to <br />{" "}
                  affiliates@gempal.exchange
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
