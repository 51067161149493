import * as React from "react";
import { ExClient } from "../models/ExClient";
import { UrlUtils } from "../utils/UrlUtils";

export const Logout = () => {
    const logout = React.useCallback(async () => {
        await ExClient.logout();
        UrlUtils.redirectTo("login");
    }, [])

    React.useEffect(() => {
        logout()
    }, []);

  return <div>Loading...</div>;
};
