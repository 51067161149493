import * as React from "react";

export const QuestionsBlock = () => {
  return (
    <section className="ask">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-center ask__title title">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ask__panel">
              <div className="ask__heading">
                <h4 className="ask__panel_title">
                  <a
                    data-toggle="collapse"
                    href="#collapse1"
                    className="ask__link"
                    aria-expanded="true"
                  >
                    What should I do if I want to buy BTC with a credit card?
                  </a>
                </h4>
              </div>
              <div id="collapse1" className="ask__collapse collapse">
                <div className="ask__body">
                  <p className="ask__text">
                    With Gempal you can buy currency online using Visa or
                    MasterCard cards. When your account is verified and the
                    preferred payment method is added, transactions will become
                    instant. The exchange process becomes fast and easy like
                    never before.{" "}
                  </p>
                  <p className="ask__text">
                    If you want to use a credit card for transactions, you
                    should add the card details during the transaction or into
                    your account details. Then choose Visa or MasterCard as a
                    payment method. Next, the amount of the crypto currency
                    should be indicated in the corresponding field. Finally, the
                    set amount will appear in your wallet.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="ask__panel">
              <div className="ask__heading">
                <h4 className="ask__panel_title">
                  <a
                    data-toggle="collapse"
                    href="#collapse3"
                    className="ask__link"
                  >
                    What steps should I take if I prefer a bank transfer?
                  </a>
                </h4>
              </div>
              <div id="collapse3" className="ask__collapse collapse">
                <div className="ask__body">
                  <p className="ask__text">
                    Using Gempal you can buy currency online by a bank transfer.
                    Gempal is processing bank transfer transactions at once but
                    the closing time for the transaction depends on your bank
                    entirely.{" "}
                  </p>
                  <p className="ask__text">
                    If you want to use a bank transfer for transactions, you
                    should send a request to support@gempal.exchange. Please,
                    write on the theme of the letter “Bank transfer” and
                    indicate the amount of the crypto currency. Our team will
                    guide you through the process.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="ask__panel">
              <div className="ask__heading">
                <h4 className="ask__panel_title">
                  <a
                    data-toggle="collapse"
                    href="#collapse4"
                    className="ask__link"
                  >
                    How can I spend Bitcoin?
                  </a>
                </h4>
              </div>
              <div id="collapse4" className="ask__collapse collapse">
                <div className="ask__body">
                  <p className="ask__text">
                    You can enjoy the coins in a huge variety of ways and modes:
                  </p>
                  <ul className="list ask__text">
                    <li>When the value rises just sell them.</li>
                    <li>Exchange to any other cryptocurrency you want.</li>
                    <li>Spend as a regular currency on your everyday needs.</li>
                    <li>Store for further investments.</li>
                    <li>
                      Store them in your Bitcoin wallet for a long term
                      investment.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="ask__panel">
              <div className="ask__heading">
                <h4 className="ask__panel_title">
                  <a
                    data-toggle="collapse"
                    href="#collapse5"
                    className="ask__link"
                  >
                    Can I use Gempal without registration?
                  </a>
                </h4>
              </div>
              <div id="collapse5" className="ask__collapse collapse">
                <div className="ask__body">
                  <p className="ask__text">
                    No, we require an account verification for using our
                    service. You need to go through a short verification
                    procedure to buy and exchange cryptocurrency. This helps us
                    to ensure security for our clients and prevent fraud. We
                    only ask for five level verification while processing
                    transactions. As soon as the data is verified, the account
                    is validated so you can enjoy all the advantages of our
                    service. The verification is required just once and valid
                    for all future transaction. However, the verification level
                    depends on the trading the sum. Please, feel free to explore
                    our <a href="amlkyc_Policy.html">KYC policy</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="ask__panel">
              <div className="ask__heading">
                <h4 className="ask__panel_title">
                  <a
                    data-toggle="collapse"
                    href="#collapse6"
                    className="ask__link"
                  >
                    What if I don’t want to provide my personal info?{" "}
                  </a>
                </h4>
              </div>
              <div id="collapse6" className="ask__collapse collapse">
                <div className="ask__body">
                  <p className="ask__text">
                    Gempal is a secure financial service, so it is required by
                    law to follow Know Your Customer standards. That is why we
                    need to know a little bit about you before you are able to
                    process your first transaction. It is required for fraud
                    prevention and security insurance. By collecting your basic
                    personal information, we keep your account safe and
                    implement the highest privacy standards.
                  </p>
                  <p className="ask__text">
                    The process of the initial verification is quick and does
                    not take long after you have submitted the information. All
                    the process from our side takes up to two days. We do not
                    provide an access to our clients’ personal information.
                    Exception are made only if required by law.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
