import * as React from "react";

export const WhyUsBlock = () => {
    return (
        <section className="why">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 text-lg-left text-center">
                        <h2 className="title why__title">Why Us</h2>
                        <p className="slogan why__slogan">People choose Gempal because we are simply the best</p>
                        <a href="exchange" className="button button_bg_red exchange-form__submit d-lg-inline-block d-none">Get started</a>
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <div className="row">
                            <div className="col-lg-6 text-lg-left text-center">
                                <div className="why__box">
                                    <div className="why__icon">
                                        <img src="img/02.svg" alt="Service" className="img-fluid how__icon_img" />
                                    </div>
                                    <h3 className="why__box_title">Various Payment Methods</h3>
                                    <ul className="list why__list">
                                        <li className="why__list_item">Multiple payment methods are offered to the customers. It is easy and fast to purchase as the payment can be processed through a bank transfer, credit and debit cards.
                                        </li>
                                    </ul>
                                </div>
                                <div className="why__box">
                                    <div className="why__icon">
                                        <img src="img/03.svg" alt="Privacy" className="img-fluid how__icon_img" />
                                    </div>
                                    <h3 className="why__box_title">Exchange Online</h3>
                                    <ul className="list why__list">
                                        <li className="why__list_item">Gempal provides fast and secure fiat for crypto and crypto for fiat exchange online.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 text-lg-left text-center">
                                <div className="why__box">
                                    <div className="why__icon">
                                        <img src="img/01.svg" alt="home" className="img-fluid how__icon_img" />
                                    </div>
                                    <h3 className="why__box_title">Security and Speed</h3>
                                    <ul className="list why__list">
                                        <li className="why__list_item">You can be confident that Gempal uses the highest standards of encryption to protect your data.</li>
                                    </ul>
                                </div>
                                <a href="exchange" className="button button_bg_red exchange-form__submit d-lg-none d-inline-block ">Get started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}