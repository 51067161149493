import {Component, default as React} from "react";
import useForm from 'react-hook-form'
import {useHistory} from "react-router-dom";

interface IRecoveryForm {
    onSubmit: (data: any) => void
}

export default function RecoveryForm(props: IRecoveryForm) {
    const { register, handleSubmit, watch, errors } = useForm();
    const history = useHistory();


    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <div className="steps__form">
                <div className="steps__form_boxing">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form__item">
                                <input name="email" type="text" className="input input_small" placeholder="Email" ref={register({ required: true, pattern: /^\S+@\S+$/i })} />
                                {errors.email && <span className="erorrs">Please, enter a valid email!</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="steps__form_footer">
                            <button type="submit" className="button button_bg_red steps-form__submit">Restore!</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}