import { Component, default as React } from "react";
import { ApiData } from "./ApiData";
import { UrlUtils } from "../utils/UrlUtils";

export class ExchangeStartModel extends Component {
  state = {
    fiatCurrency: "EUR",
    cryptoCurrency: "BTC",
    fiat_amount: 0,
    crypto_amount: 0,
    fiat_prices: { EUR: 1 },
    crypto_prices: {},
    is_authorized: false,
    temp_email: "",
    is_loading: true,
    is_checking: true,
    is_payment_selected: false,
    exchangeAmountLimit: {},
  };

  onChangeFiat = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //console.log("----", event.target.selectedIndex, event.target.selectedOptions[0].text)
    if (!event.target || event.target.selectedOptions.length == 0) return;

    var fiatCurrency = event.target.selectedOptions[0].text;
    this.setState({
      fiatCurrency: fiatCurrency,
      crypto_amount: this._getCryptoInput(
        this.state.crypto_prices,
        this.state.fiat_amount,
        fiatCurrency,
        this.state.cryptoCurrency
      ),
    });
  };

  onChangeCrypto = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target) {
      var cryptoCurrency = event.target.selectedOptions[0].text;
      this.setState({
        cryptoCurrency: cryptoCurrency,
        crypto_amount: this._getCryptoInput(
          this.state.crypto_prices,
          this.state.fiat_amount,
          this.state.fiatCurrency,
          cryptoCurrency
        ),
      });
    }
  };

  onInputFiat = (event: React.ChangeEvent<HTMLInputElement>) => {
    var amount = Number(event.target.value);
    if (isNaN(amount) || amount > 1000000) return;

    this.setState({
      fiat_amount: event.target.value,
      crypto_amount: this._getCryptoInput(
        this.state.crypto_prices,
        amount,
        this.state.fiatCurrency,
        this.state.cryptoCurrency
      ),
    });
  };

  onInputCrypto = (event: React.ChangeEvent<HTMLInputElement>) => {
    var amount = Number(event.target.value);
    if (isNaN(amount) || amount > 1000000) return;

    this.setState({
      crypto_amount: event.target.value,
      fiat_amount: this._getFiatInput(amount, this.state.cryptoCurrency),
    });
  };

  _getCryptoInput(
    prices: Record<string, number>,
    fiat_amount: number,
    cur_fiat: string,
    cur_crypto: string
  ) {
    console.log("Data", this.state.fiat_prices, prices);
    if (!this.state.fiat_prices) return 0;
    var result =
      fiat_amount / this.state.fiat_prices[cur_fiat] / prices[cur_crypto];
    return result.toFixed(6);
  }

  _getFiatInput(crypto_amount: number, cur: string) {
    var result =
      crypto_amount *
      this.state.fiat_prices[this.state.fiatCurrency] *
      this.state.crypto_prices[cur];
    return result.toFixed(2);
  }

  public loadData(amount: number = 200): void {
    ApiData.getCurrenciesExchange().then((data) => {
      let cryptoCurrency = UrlUtils.getUrlParam("to");
      if (!cryptoCurrency) cryptoCurrency = this.state.cryptoCurrency;
      let newState = {
        fiat_amount: amount,
        fiat_prices: data.fiat_prices,
        crypto_prices: data.crypto_prices,
        fiatCurrency: this.state.fiatCurrency,
        cryptoCurrency: cryptoCurrency,
        crypto_amount: this._getCryptoInput(
          data.crypto_prices,
          amount,
          this.state.fiatCurrency,
          cryptoCurrency
        ),
      };
      this.setState({ ...newState }, () =>
        this.setState({ is_loading: false })
      );
    });
  }

  loadExchangeAmountLimit = async () => {
    const { success, data } = await ApiData.publicApiRequest(
      {},
      "get_exchange_amount_limit"
    );
    if (success) this.setState({ exchangeAmountLimit: data });
  };
}
