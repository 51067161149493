import {Component, default as React} from "react";
import {JustExchangedBlock} from "./JustExchangedBlock";
import {NumUtils} from "../../utils/NumUtils";

export class GetBtcBlock extends Component {
    current_date = new Date();

    render = () => (
        <section className="customers light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="text-center customers__title title">Get you BTC using Gempal from more<br /> than 166 countries!</h2>
                    </div>
                </div>
                <div className="row">
                    <JustExchangedBlock />

                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-7 text-lg-right text-center">
                                <img src="img/diagram.svg" alt="diagram" className="img-fluid customers__diagram" />
                            </div>
                            <div className="col-lg-5 d-lg-flex  justify-content-end">
                                <ul className="list customers__list">
                                    <li className="customers__item text-lg-left text-center">
                                        <span className="customers__list_text">New Users Today</span>
                                        <span className="customers__list_summ">12</span>
                                        {/* <span className="customers__list_summ">{NumUtils.getPseudoRandomInt(this.current_date.getDay(), 7, 100)}</span> */}
                                    </li>
                                    <li className="customers__item text-lg-left text-center">
                                        <span className="customers__list_text">New Users This Month</span>
                                        <span className="customers__list_summ">362</span>
                                        {/* <span className="customers__list_summ">{NumUtils.getPseudoRandomInt(this.current_date.getMonth() + (this.current_date.getFullYear() % 2), 1600, 3000)}</span> */}
                                    </li>
                                    <li className="customers__item text-lg-left text-center">
                                        <span className="customers__list_text">New Users This Year</span>
                                        <span className="customers__list_summ">1135</span>
                                        {/* <span className="customers__list_summ">{this.current_date.getFullYear() == 2020 ? 19400 : NumUtils.getPseudoRandomInt(this.current_date.getFullYear() - 2000, 19400, 36000)}</span> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}