import { Component, default as React } from "react";
import { StringUtils } from "../../utils/StringUtils";
import { ACTIONS, ExchangeSession } from "../../models/ExchangeSession";
import { ExchangeStepsLine } from "./ExchangeStepsLine";

import ExchangeSummaryDetails from "./ExchangeSummaryDetails";
import { toast } from "react-toastify";
import { ApiData, ExceptionCodes, getApiDataPromise } from "../../models/ApiData";
import { ExchangeStep3Form } from "./ExchangeStep3Form";
import { ExClient } from "../../models/ExClient";
import { UrlUtils } from "../../utils/UrlUtils";
import { Exchange } from "../../models/Exchange";
import { ExchangeStep3Message } from "./ExchangeStep3Message";

export class ExchangeStep3DocContent extends Component {
  state = {
    fiatCurrency: "EUR",
    cryptoCurrency: "BTC",
    wallet: "",
    fiat_amount: 0,
    ex_transaction_id: false,
    payment_token: false,
    is_loaded: false,
    is_default_shop: false,
    main_docs_is_uploaded: false,
  };

  public async componentDidMount(): Promise<void> {
    ExchangeSession.redirectIfWrongStep("3");

    const state = ExchangeSession.getExchangeState();
    if (state) this.setState(state);

    const { verify_flags, is_default_shop, main_docs_is_uploaded, is_verified } = await ExClient.getVerifyInfo();
    this.setState({ is_default_shop, main_docs_is_uploaded });

    if (!is_default_shop) {
      await this.createTransaction();
    }
    await this.checkIfVerified(verify_flags, is_default_shop, is_verified);

    this.setState({ is_loaded: true });
  }

  createTransaction = async () => {
    const session_data = ExchangeSession.data;

    if (!session_data["ex_transaction_id"]) {
      if (session_data["wallet"]) {
        const result = await Exchange.create(false);

        if (result.success) {
          this.setState({ ex_transaction_id: result.data.ex_transaction_id, payment_token: result.data.token });
        } else {
          ExchangeSession.saveDataParam("step", "5");
          ExchangeSession.saveDataParam("status_info", result.message);
          UrlUtils.redirectTo("exchange?step=5");
        }
      } else {
        ExchangeSession.cancel();
        toast.error("Invalid data! Please contact support.");
        UrlUtils.redirectTo("exchange");
      }
    } else this.setState({ ex_transaction_id: session_data["ex_transaction_id"] });
  };

  checkIfVerified = async (verify_flags: any, is_default_shop: boolean, is_verified: boolean) => {
    if ((!is_default_shop && ExClient.isVerified(verify_flags)) || (is_default_shop && is_verified)) {
      if (is_default_shop) {
        await this.createTransaction();
      }

      ExchangeSession.saveDataParam("step", "4");
      UrlUtils.redirectTo("exchange?step=4");
    }
  };

  onFilesUploaded = () => {
    this.setState({ main_docs_is_uploaded: true });
    if (!this.state.is_default_shop) {
      ExchangeSession.saveDataParam("step", "4");
      UrlUtils.redirectTo("exchange?step=4");
    }
  };

  render() {
    const { ex_transaction_id, is_loaded, is_default_shop, main_docs_is_uploaded } = this.state;
    return (
      <div className="body__steps">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 steps__dots_bg">
              <ExchangeStepsLine step_id={2} />
            </div>
            <div className="col-lg-10">
              <div className="steps__box steps__box_boxing">
                <div className="row no-gutters">
                  <div className="steps__part_big">
                    <div className="steps__box_light">
                      <h3 className="steps__box_title">
                        <span className="d-inline-block position-relative">Upload Documents to verify your account</span>
                      </h3>
                      {is_loaded && (
                        <>
                          {is_default_shop && main_docs_is_uploaded ? (
                            <ExchangeStep3Message />
                          ) : (
                            <ExchangeStep3Form
                              onFilesUploaded={this.onFilesUploaded}
                              ex_transaction_id={ex_transaction_id}
                              is_default_shop={is_default_shop}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="steps__part_small">
                    <div className="steps__box_dark">
                      <ExchangeSummaryDetails
                        ex_transaction_id={this.state.ex_transaction_id}
                        payment_token={this.state.payment_token}
                        wallet={this.state.wallet}
                        fiat_amount={this.state.fiat_amount}
                        crypto_currency={this.state.cryptoCurrency}
                        fiat_currency={this.state.fiatCurrency}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
