import * as React from "react";
import {AssetsContent} from "../index/AssetsContent";

export const AboutContent = () => {
    return (
        <>
            <section className="today">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="title title__today">Today</h2>
                            <p className="slogan slogan__today">We are proud to be one of the most reliable <br />companies
                                in cryptocurrency exchange</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="today__item">
                                <img src="img/about/icons/02/01.svg" alt="icons" className="today__ic" />
                                    <h3 className="today__title">Purchase, Sale, Exchange</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="today__item">
                                <img src="img/about/icons/02/02.svg" alt="icons" className="today__ic" />
                                    <h3 className="today__title">Autoexchange</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="today__item">
                                <img src="img/about/icons/02/03.svg" alt="icons" className="today__ic" />
                                    <h3 className="today__title">Discount Program</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="today__item">
                                <img src="img/about/icons/02/04.svg" alt="icons" className="today__ic" />
                                    <h3 className="today__title">Affiliate Program</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="today__item">
                                <img src="img/about/icons/02/05.svg" alt="icons" className="today__ic" />
                                    <h3 className="today__title">API</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="today__item">
                                <img src="img/about/icons/02/06.svg" alt="icons" className="today__ic" />
                                    <h3 className="today__title">Support</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AssetsContent classParam={"assets__about"} />

 

            <section className="benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="title title__benefits">Our benefits</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/about/icons/05/01.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">BEST CURRENCY EXCHANGE RATE </h3>
                                    <p className="benefits__des">We offer the best currency exchange rate. No hidden
                                        commissions or charges. All the calculations are shown immediately.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/about/icons/05/02.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">Security and reliability</h3>
                                    <p className="benefits__des">For security reasons we use the latest IT Technologies.
                                        Service transactions and your personal data security are above all. </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/about/icons/05/03.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">24 HOUR CS</h3>
                                    <p className="benefits__des">We never sleep! Contact us at anytime – we will help
                                        you with any your request!</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/about/icons/05/04.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">Multi language system</h3>
                                    <p className="benefits__des">Three languages are available: English, French, and Polish.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/about/icons/05/05.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">No commission</h3>
                                    <p className="benefits__des">Choose the operation you need with 0 commission.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/about/icons/05/06.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">Discount program</h3>
                                    <p className="benefits__des">For our VIP clients we have 10% discount.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <a href="sign_up" className="button button-big button_bg_red benefits__submit">Register</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="legal light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 text-lg-left text-center">
                            <h2 className="title legal__title">Legal and Security</h2>
                            <p className="legal__text">Gempal’s priority is securing clients’ funds and personal data.
                                We have an exceptional security technology infrastructure with a login guard, a
                                two-factor authentication and the highest data encryption standards. We ensure complete
                                legal compliance of the exchange by the AML and KYC policies. For more information,
                                please, check our security page.</p>
                            <p className="legal__text">Learn more on our <a href="/terms_of_use.html" className="text__link">security
                                page.</a></p>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-6 text-lg-left text-center">
                                    <div className="why__box">
                                        <div className="why__icon">
                                            <img src="img/about/icons/06/03.svg" alt="home"
                                                 className="img-fluid how__icon_img" />
                                        </div>
                                        <h3 className="why__box_title">EXCHANGE ONLINE</h3>
                                        <ul className="list why__list">
                                            <li className="why__list_item">Gempal provides fast and secure fiat for crypto and crypto for fiat exchange online.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="why__box">
                                        <div className="why__icon">
                                            <img src="img/about/icons/06/02.svg" alt="Privacy"
                                                 className="img-fluid how__icon_img" />
                                        </div>
                                        <h3 className="why__box_title">VARIOUS PAYMENT METHODS</h3>
                                        <ul className="list why__list">
                                            <li className="why__list_item">Multiple payment methods are offered to the
                                                customers. It is easy and fast to purchase as the payment can be
                                                processed through a bank transfer, credit and debit cards.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-lg-left text-center">
                                    <div className="why__box">
                                        <div className="why__icon">
                                            <img src="img/about/icons/06/01.svg" alt="Service"
                                                 className="img-fluid how__icon_img" />
                                        </div>
                                        <h3 className="why__box_title">SECURITY AND SPEED</h3>
                                        <ul className="list why__list">
                                            <li className="why__list_item">
                                                You can be confident that Gempal uses the highest standards of encryption to protect your data.
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="sign_up"
                                        className="button button_bg_red exchange-form__submit d-lg-none d-inline-block ">Get
                                        started
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}