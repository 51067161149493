import * as React from "react";

export const AboutInfoContent = () => {
  return (
    <div className="container-fluid light">
      <div className="row">
        <div className="col-lg-12">
          <div className="main-about__content text-center">
            <h2 className="title title__about">About Us</h2>
            <div className="main-about__picture">
              <img src="img/about.png" alt="about_pic" className="img-fluid" />
            </div>
            <div className="main-about__text_wrap">
              <p className="main-about__text">
                Gempal is dedicated to staying ahead of trends. We have
                implemented an API interface that enables users to perform
                operations directly on the platform.
              </p>
              <p className="main-about__text">
                At Gempal, we prioritize our clients and consider each one
                important. That's why we are now offering learning programs
                where individuals can discover how to generate income.
                Additionally, we have launched the Affiliate Program, allowing
                affiliates to earn up to 20% from transactions.
              </p>
              <p className="main-about__text">
                We moved forward and implemented an API interface, giving an
                opportunity to carry out operations on the platform.{" "}
              </p>
              <p className="main-about__text">
                Each client is important, so we offer learning programs on good
                conditions and an opportunity to make money on your own.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
