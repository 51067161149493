import * as React from "react";
import LoginForm from "./LoginForm";
import { Promo } from "./Promo";
import { EmailVerifyModal } from "./EmailVerifyModal";
import { VerifiedBlock } from "./VerifiedBlock";
import { Modal } from "../Modal";

export interface LoginProps {
  showEmailVerifyBlock: boolean;
  showIPVerifyInfo: boolean;
}

export const LoginContent = ({
  showEmailVerifyBlock = false,
  showIPVerifyInfo = false,
}: LoginProps) => (
  <>
    <div className="body__steps login">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="steps__box">
              <div className="row no-gutters">
                <div className="steps__part_big steps__part_big_bg">
                  <div className="steps__box_light">
                    <div className="row">
                      <div className="col-md-4">
                        <h3 className="steps__box_title">Login </h3>
                      </div>
                      <div className="col-md-8">
                        <h3 className="steps__box_title text-md-right text-left">
                          <span className="title__light">
                            No Gempal’s account?{" "}
                          </span>
                          <a href="sign_up" className="title__link">
                            Register
                          </a>
                        </h3>
                      </div>
                    </div>
                    <VerifiedBlock
                      visible={showEmailVerifyBlock}
                      text={"Your Email has been verified!"}
                    />
                    <VerifiedBlock
                      visible={showIPVerifyInfo}
                      text={"Your IP has been verified!"}
                    />

                    {LoginForm()}
                  </div>
                </div>

                <Promo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EmailVerifyModal />
    <Modal id={"ip_verify"}>
      <img src="img/03.svg" alt="icons" className="modal__icon" />
      <h3 className="modal__title">UNUSUAL ACTIVITY DETECTED</h3>
      <p className="modal__text">
        IP address verification before starting is mandatory.
      </p>
      <p className="modal__text">Instructions have been sent to your email</p>
    </Modal>
  </>
);
