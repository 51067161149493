import * as React from "react";
import { ApiData } from "../../models/ApiData";
import { ExClient } from "../../models/ExClient";
import { Exchange } from "../../models/Exchange";

export interface ExchangeSummaryDetailsProps {
  fiat_amount: number;

  fiat_currency: string;
  crypto_currency: string;
  wallet?: string;

  ex_transaction_id?: any;
  payment_token?: any;

  onRateUpdate?: (crypto_amount: any) => void;
  is_status_page?: boolean;
}

export default class ExchangeSummaryDetails extends React.Component<
  ExchangeSummaryDetailsProps,
  {}
> {
  private timer: any;

  state = {
    data_list: [{}],
    timer_countdown: 0,
    rate: 0,
    transaction_fee_pct: 0,
  };

  async updateRate() {
    if (this.props.payment_token) {
      let trans = await Exchange.getTransactionAndUpdateByPaymentToken(
        this.props.payment_token,
        this.props.is_status_page
      );
      this.onResultRateData(trans);
    } else {
      let newRate = await Exchange.getRateAndCommission(
        this.props.crypto_currency,
        this.props.fiat_currency,
        ExClient.shop_id
      );
      this.onResultRateData(newRate);
    }
  }

  onResultRateData(newRate: any) {
    if (newRate && newRate.rate != this.state.rate) {
      let d = {
        rate: newRate.rate,
      };

      if (newRate.transaction_fee_pct)
        d["transaction_fee_pct"] = newRate.transaction_fee_pct;

      this.setState(d);

      if (this.props.onRateUpdate)
        this.props.onRateUpdate(this.getCryptoAmount());
    }
  }

  public componentDidMount(): void {
    this.timer = setInterval(() => {
      if (this.state.timer_countdown > 0) {
        this.setState({
          timer_countdown: this.state.timer_countdown - 1,
        });
      } else {
        this.setState({
          timer_countdown: 15,
        });
        this.updateRate();
      }
    }, 1000);
  }

  public componentWillUnmount(): void {
    if (this.timer) clearInterval(this.timer);
  }

  public componentDidUpdate(
    prevProps: ExchangeSummaryDetailsProps,
    prevState: any
  ): void {
    if (
      this.props.fiat_currency != prevProps.fiat_currency ||
      this.props.crypto_currency != prevProps.crypto_currency ||
      prevProps.fiat_amount != this.props.fiat_amount
    ) {
      this.updateRate();
    }
  }

  getCryptoAmount = () => (this.props.fiat_amount / this.state.rate).toFixed(6);

  public render() {
    const processing_fee: number =
      (this.props.fiat_amount / 100) * this.state.transaction_fee_pct;
    const total_spent: number = Number(this.props.fiat_amount) + processing_fee;
    const timeDiv = !this.props.is_status_page ? (
      <div className="steps__informations">
        <p className="steps__informations_time">
          The price is recalculated in {this.state.timer_countdown}
          sec <img src="img/refresh.svg" alt="loader" className="loader" />
        </p>
        <a
          href="#"
          className="steps__informations_question"
          data-html="true"
          data-toggle="popover"
          title="Note <a href='#' class='close' data-dismiss='alert'><img src='img/icons/close_d.svg'</a>"
          data-content="We monitor the live price changes at a couple of the largest trading platforms. Our cryptocurrency price is determined by an average value of the most recent trade values on the market."
        >
          How is the price calculated?
        </a>
        <p className="steps__informations_question" style={{ marginTop: 10 }}>
          Item’s value may fluctuate or be volatile
        </p>
      </div>
    ) : (
      ""
    );

    return (
      <>
        <div className="steps__box_top d-flex justify-content-between">
          <h3 className="steps__box_title">Cart</h3>
          <h3 className="steps__box_title text-success d-flex align-items-center">
            <img src="img/payment/icobs/secure.svg" alt="payment" />
            <span className="steps__box_title_text"> SSL Secure</span>
          </h3>
        </div>
        {this.state.rate > 0 && (
          <div className="steps__ex d-flex">
            <div className="steps__ex_start">
              <span className="steps__ex_sum">{this.props.fiat_amount}</span>
              <span className="steps__ex_cur">{this.props.fiat_currency}</span>
            </div>
            <img src="img/change.svg" alt="change" className="steps__ex_ic" />
            <div className="steps__ex_end">
              <span className="steps__ex_sum">{this.getCryptoAmount()}</span>
              <span className="steps__ex_cur">
                {this.props.crypto_currency}
              </span>
            </div>
          </div>
        )}
        {timeDiv}
        {this.state.rate > 0 && (
          <div className="steps__data">
            {this.props.ex_transaction_id && (
              <div className="row steps__data_row">
                <div className="col-lg-5">
                  <span className="steps__data_title">Transaction ID:</span>
                </div>
                <div className="col-lg-6">
                  <span className="steps__data_val">
                    {this.props.ex_transaction_id}
                  </span>
                </div>
              </div>
            )}

            <div className="row steps__data_row">
              <div className="col-lg-5">
                <span className="steps__data_title">Exchange rate:</span>
              </div>
              <div className="col-lg-6">
                <span className="steps__data_val">
                  {this.state.rate} {this.props.fiat_currency}
                </span>
              </div>
            </div>
            <div className="row steps__data_row">
              <div className="col-lg-5">
                <span className="steps__data_title">Processing fee:</span>
              </div>
              <div className="col-lg-6">
                <span className="steps__data_val">
                  {processing_fee.toFixed(2)} {this.props.fiat_currency}
                </span>
              </div>
            </div>
            <div className="row steps__data_row">
              <div className="col-lg-5">
                <span className="steps__data_title">Total spent:</span>
              </div>
              <div className="col-lg-6">
                <span className="steps__data_val">
                  {total_spent.toFixed(2)} {this.props.fiat_currency}
                </span>
              </div>
            </div>
            <div className="row steps__data_row">
              <div className="col-lg-5">
                <span className="steps__data_title">Total received:</span>
              </div>
              <div className="col-lg-6">
                <span className="steps__data_val">
                  {this.getCryptoAmount()} {this.props.crypto_currency}
                </span>
              </div>
            </div>

            {this.props.wallet && (
              <div className="row steps__data_row">
                <div className="col-lg-5">
                  <span className="steps__data_title">
                    Your BTC wallet address:
                  </span>
                </div>
                <div className="col-lg-6">
                  <span className="steps__data_val">{this.props.wallet}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
