import { Component, CSSProperties, default as React, useCallback, useEffect, useState } from "react";
import { ButtonCancel } from "./ButtonCancel";

import ReactDropzone, { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { ExDocumentType } from "../../models/types/ExDocumentType";
import { ApiData } from "../../models/ApiData";
import { ExClient } from "../../models/ExClient";
import { ExchangeSession } from "../../models/ExchangeSession";
import { Modal } from "../Modal";
import { TermsAndConditionsLabel } from "./TermsAndConditionsLabel";
import { HelpNote } from "../../components/HelpNote";

export interface IExchangeStep3FormProps {
  onFilesUploaded: () => void;
  ex_transaction_id: any;
  is_default_shop: boolean;
}

export class ExchangeStep3Form extends Component<IExchangeStep3FormProps, {}> {
  passport_file = false;
  card_file = false;
  upload_in_progress = false;

  private agree_terms: React.RefObject<HTMLInputElement> = React.createRef();
  private agree_age: React.RefObject<HTMLInputElement> = React.createRef();

  private modal: React.RefObject<Modal> = React.createRef();

  state = {
    passport_uploaded: false,
    card_uploaded: false,
  };

  public componentDidMount(): void {
    const data = ExchangeSession.data;
    //this.agree_terms.current.checked = this.agree_age.current.checked = false;

    if (data["card_uploaded"] || data["card_uploaded"]) {
      const new_state = {
        passport_uploaded: data["card_uploaded"] == "1",
        card_uploaded: data["card_uploaded"] == "1",
      };

      if (new_state.card_uploaded && new_state.passport_uploaded) this.props.onFilesUploaded();
      this.setState(new_state);
    }
  }

  onSubmitForm = async (e: any) => {
    e.preventDefault();
    if (this.upload_in_progress) return;
    console.log("step3", this.passport_file, this.agree_terms.current.checked);

    if (!this.agree_terms.current.checked) return toast.error("Please accept Terms & Conditions!");

    if (!this.agree_age.current.checked) return toast.error("Please accept that you are over 18 years old!");

    if (!this.passport_file) return toast.error("Please select a passport image.");

    if (!this.card_file) return toast.error("Please select a card image.");

    this.uploadDocuments();
  };

  uploadDocuments = async () => {
    this.upload_in_progress = true;

    const result_passport_upload = await ExClient.uploadDocument(this.passport_file, ExDocumentType.PASSPORT.toString());
    console.log("result_passport_upload", result_passport_upload, result_passport_upload.success);
    if (result_passport_upload.success) {
      console.log("CARD UPLOAD");
      this.setState({ passport_uploaded: true });
      ExchangeSession.saveDataParam("passport_uploaded", "1");
      const result_card_upload = await ExClient.uploadDocument(this.card_file, ExDocumentType.SELFIE_CARD.toString());
      if (result_card_upload.success) {
        this.setState({ card_uploaded: true });
        ExchangeSession.saveDataParam("card_uploaded", "1");
        this.props.onFilesUploaded();
      } else toast.error("Error uploading card image to server");
    } else toast.error("Error uploading passport image to server");

    this.upload_in_progress = false;
  };

  onDropPassport = (files: [any]) => {
    if (files.length) this.passport_file = files[0];
    else this.passport_file = null;
    console.log("passport", this.passport_file);
  };

  onDropCard = (files: [any]) => {
    if (files.length) this.card_file = files[0];
    else this.card_file = null;
  };

  onSkipRequest = (e: any) => {
    e.preventDefault();
    this.modal.current.toggle();
  };

  onSkipConfirm = (e: any) => {
    e.preventDefault();
    this.props.onFilesUploaded();
  };

  render = () => (
    <>
      <form onSubmit={this.onSubmitForm}>
        <div className="steps__form">
          <div className="steps__requirement_title d-flex align-items-center" style={{ zIndex: 1000, cursor: "pointer" }}>
            Requirements
            <HelpNote
              children={<>Photo / scan of the passport (quality so that we can see all the records and numbers)</>}
              icon={<span className="popover_exclamation ">!</span>}
            />
          </div>
          <ul className="steps__requirements list">
            <li className="steps__requirements_item">Supported formats: JPG, PNG and PDF;</li>
            <li className="steps__requirements_item">Documents should be up-to-date;</li>
            <li className="steps__requirements_item">Provide coloured high-resolution images (at least 20 dpi);</li>
            <li className="steps__requirements_item">Documents must be at least 50KB and no more than 8MB.</li>
          </ul>
          <div className="row">
            <div className="col-lg-6">
              <div className="steps__upload steps__upload_doc" style={{ zIndex: 100000 }}>
                <div className="d-flex justify-content-end" style={{ padding: "20px 20px 0" }}>
                  <HelpNote
                    className="steps__help_popup"
                    icon={<img src="img/payment/icons/help1.svg" alt="help" />}
                    children={
                      <>
                        <p>The passport or ID card provided for verification must contain the following information:</p>
                        <ul style={{ paddingLeft: 20 }}>
                          <li>Country that issued the document</li>
                          <li>The serial number of the document proving the identity</li>
                          <li>Date of issue</li>
                          <li>Gender</li>
                          <li>Surname</li>
                          <li>Name</li>
                          <li>Date of birth</li>
                          <li>Place of birth</li>
                          <li>Date of expiry</li>
                        </ul>
                      </>
                    }
                  />
                </div>
                <label className="custom_file_label steps__upload_wrap text-center">
                  <Previews
                    lab={passportUploadInfo}
                    name={"passport"}
                    uploaded={this.state.passport_uploaded}
                    onDrop={this.onDropPassport}
                  />
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="steps__upload steps__upload_card" style={{ zIndex: 1000 }}>
                <div className="d-flex justify-content-end" style={{ padding: "20px 20px 0" }}>
                  <HelpNote
                    className="steps__help_popup"
                    icon={<img src="img/payment/icons/help1.svg" alt="help" />}
                    children="Please upload a copy of the payment card used revealing the first six and the last four digits"
                  />
                </div>
                <label className="custom_file_label steps__upload_wrap text-center">
                  <Previews lab={cardUploadInfo} name={"card"} onDrop={this.onDropCard} uploaded={this.state.card_uploaded} />
                </label>
              </div>
            </div>
          </div>
          {this.props.ex_transaction_id && !this.props.is_default_shop && (
            <div className="row">
              <div className="col-lg-12">
                <a href="#" onClick={this.onSkipRequest} className="steps__later">
                  Skip, i’ll do it later
                </a>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-12">
              <div className="step__box_agree">
                <div className="custom-control custom-checkbox">
                  <input
                    id="agree"
                    name="agree_terms"
                    defaultChecked={false}
                    type="checkbox"
                    className="custom-control-input"
                    ref={this.agree_terms}
                  />
                  <TermsAndConditionsLabel />
                </div>
              </div>
              <div className="step__box_agree">
                <div className="custom-control custom-checkbox">
                  <input
                    id="old"
                    name="agree_age"
                    defaultChecked={false}
                    type="checkbox"
                    className="custom-control-input"
                    ref={this.agree_age}
                  />
                  <label className="custom-control-label" htmlFor="old">
                    Yes, I am over the age of 18
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="steps__form_footer">
                <button type="submit" className="button button_bg_red steps-form__submit">
                  Continue
                </button>
                <ButtonCancel />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal ref={this.modal} id="skip_modal">
        <img src="img/03.svg" alt="icons" className="modal__icon" />
        <h3 className="modal__title">Verification notice</h3>
        <p className="modal__text">Verification skipping results in processing delay.</p>
        <div className="modal-buttons">
          <button className="button button_bg_red modal__ok" onClick={this.onSkipConfirm}>
            Ok, I understand
          </button>
          <button type="button" className="btn btn-link steps__btn-link" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}

const passportUploadInfo = (
  <>
    <img src="img/payment/icons/04.svg" alt="icons" className="steps__upload_ic" />
    <span className="steps__upload_text">
      Upload your
      <br /> passport, ID or
      <br /> Driving License
    </span>

    <p className="steps__upload_des">
      {" "}
      Drag file here to upload or <span className="text-danger">select a file</span> from your device
    </p>
  </>
);

const cardUploadInfo = (
  <>
    <img src="img/payment/icons/05.svg" alt="icons" className="steps__upload_ic" />
    <span className="steps__upload_text">
      Upload a photo of
      <br /> payment card
    </span>

    <p className="steps__upload_des">
      {" "}
      Drag file here to upload or <span className="text-danger">select a file</span> from your device
    </p>
  </>
);

function Previews(props: any) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    preventDropOnDocument: true,
    disabled: false,
    multiple: false,
    noDrag: false,
    noDragEventsBubbling: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (props.onDrop) props.onDrop(acceptedFiles);
    },
  });

  /*
    const uploaders = files.map(file => {
        const formData = new FormData()
        formData.append('file', file);

        return axios.post('https://httpbin.org/post', formData, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
    })
*/

  const thumbs =
    files.length > 0 ? (
      files.map((file) => (
        <div style={thumb} key={file.name}>
          <img src={file.preview} style={img} />
        </div>
      ))
    ) : (
      <div onClick={(e) => e.preventDefault()}>{props.lab}</div>
    );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <>
        {" "}
        {props.uploaded ? (
          <strong>File has been uploaded!</strong>
        ) : (
          <div {...getRootProps({ className: "dropzone" })}>
            {thumbs}
            <input name={props.name} onClick={(e) => e.stopPropagation()} {...getInputProps()} />
          </div>
        )}
      </>
    </section>
  );
}

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #999",
  marginBottom: "8px",
  marginRight: "8px",
  lineHeight: "25px",
  height: "auto",
  padding: "4px",
};

const img = {
  display: "block",
  width: "150px",
  height: "150px",
};
