import {Component, default as React} from "react";
import {ApiData} from "../../models/ApiData";

interface IRatesCurrencyListProps {
    onSelect: (symbol: string) => void,
    className: string
}

export class FiatSelectButtons extends Component<IRatesCurrencyListProps, {}> {
    state = {
        currencies: [{}],
        selected: "EUR"
    };

    async componentDidMount():  Promise<void> {
        let currencies = await ApiData.getSelectorList("fiat");
        this.setState({currencies: currencies});
    }

    onSelect = (e:React.MouseEvent<HTMLButtonElement>) =>
    {
        e.preventDefault();
        const symbol = e.currentTarget.value;
        if(this.state.selected !== symbol) {
            this.setState({selected: symbol});
            this.props.onSelect(symbol);
        }
    }

    render = () => (
        <>
            {this.state.currencies.filter((curr) => (curr as {key: string}).key !== "USD").map((k: any, index: number) =>
                <button value={k.key} onClick={this.onSelect} type="button" key={index} className={this.props.className + (k.key === this.state.selected ? " active" : "")} >{k.key}</button>)}
        </>
    );
}