import * as React from "react";
import { Component } from "react";
import { ExClient } from "../models/ExClient";
import { DefaultValues } from "../models/DefaultValues";

export interface HeaderProps {
  isDark?: boolean;
}

export class Header extends Component<HeaderProps, any> {
  state = {
    is_authorized: ExClient.isAuthorized,
    //is_loaded: ExClient.isAuthorized
  };

  async componentDidMount(): Promise<void> {
    const is_authorized = await ExClient.checkLogin();
    this.setState({
      is_authorized: is_authorized,
      //is_loaded: true
    });
  }

  render = () => {
    const isDark = this.props.isDark || this.props.isDark == null;
    const dashboard_url = DefaultValues.CLIENT;

    let theme = isDark
      ? {
          navbarClass: "navbar navbar-expand-lg light",
          logo: "white",
        }
      : {
          navbarClass: "navbar navbar-expand-lg dark",
          logo: "black",
        };

    return (
      <header className="header">
        <div className="container">
          <nav className={theme.navbarClass}>
            <a className="navbar-brand navbar-brand_light" href="/">
              <img
                src={"img/logo_" + theme.logo + ".png"}
                alt="logo"
                className="navbar-brand_header"
              />
            </a>

            <div className="d-flex">
              {/*
                            <div className="dropdown navbar__dropdown navbar__dropdown_mob d-lg-none d-block">
                                <a className="btn navbar__dropdown_btn dropdown-toggle" href="#" role="button"
                                   id="dropdownCountry" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="img/USA.svg" alt="USA" />
                                </a>
                                <div className="navbar__dropdown-menu dropdown-menu" aria-labelledby="dropdownCountry">
                                    <a className="dropdown-item" href="#"><img src="img/USA.svg" alt="USA" /></a>
                                    <a className="dropdown-item" href="#"><img src="img/russia.svg" alt="russia" /></a>
                                    <a className="dropdown-item" href="#"><img src="img/germany.svg" alt="germany" /></a>
                                    <a className="dropdown-item" href="#"><img src="img/france.svg" alt="france" /></a>
                                </div>
                            </div>*/}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img src={"img/menu_" + theme.logo + ".svg"} alt="menu" />
              </button>
            </div>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="nav navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="rates">
                    Rates
                  </a>
                </li>
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="affiliate">Affiliate</a>*/}
                {/*</li>*/}
                <li className="nav-item">
                  <a className="nav-link" href="exchange">
                    Buy Crypto
                  </a>
                </li>
                {this.state.is_authorized && (
                  <li className="nav-item active">
                    <a className="nav-link" href={dashboard_url}>
                      Dashboard
                    </a>
                  </li>
                )}
                {/*
                                <li className="nav-item d-lg-block d-none">
                                    <div className="dropdown navbar__dropdown">
                                        <a className="btn navbar__dropdown_btn dropdown-toggle" href="#" role="button"
                                           id="dropdownCountrmob" data-toggle="dropdown" aria-haspopup="true"
                                           aria-expanded="false">
                                            <img src="img/USA.svg" alt="USA" />
                                        </a>
                                        <div className="navbar__dropdown-menu dropdown-menu"
                                             aria-labelledby="dropdownCountrmob">
                                            <a className="dropdown-item" href="#"><img src="img/USA.svg" alt="USA" /></a>
                                            <a className="dropdown-item" href="#"><img src="img/russia.svg"
                                                                                       alt="russia" /></a>
                                            <a className="dropdown-item" href="#"><img src="img/germany.svg" alt="germany" /></a>
                                            <a className="dropdown-item" href="#"><img src="img/france.svg"
                                                                                       alt="france" /></a>
                                        </div>
                                    </div>
                                </li>
                                */}
                {this.state.is_authorized ? (
                  <>
                    <li className="nav-item d-lg-none d-block">
                      <a
                        className="navbar_signOut radius button_bg_red"
                        href="#"
                        onClick={async () => await ExClient.logout()}
                      >
                        Logout
                      </a>
                    </li>

                    <li className="nav-item d-lg-block d-none">
                      <a
                        className="navbar_signOut radius button_bg_red"
                        href="#"
                        onClick={async () => await ExClient.logout()}
                      >
                        Logout
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item d-lg-block d-none">
                      <a href="login" className="nav-link">
                        Login
                      </a>
                    </li>
                    <li className="nav-item d-lg-block d-none">
                      <a href="sign_up" className="navbar_signUp radius">
                        Sign up
                      </a>
                    </li>

                    <li className="nav-item d-lg-none d-block">
                      <a className="navbar_signUp radius" href="login">
                        Login
                      </a>
                    </li>
                    <li className="mobile__item nav-item d-lg-none d-block text-center">
                      <span className="mobile__text">
                        Don't have an account?
                        <a href="sign_up" className="mobile__link">
                          {" "}
                          Register
                        </a>
                      </span>
                    </li>
                  </>
                )}
                {/* <li className="mobile__item nav-item d-lg-none d-flex align-items-center justify-content-center">
                                    <a href="" className="header__social_link social_link"> <img
                                        src={isDark ? "img/footer-w/social/fb.svg" : "img/footer-d/social/fb.svg"}
                                        alt="fb" className="social_img" /> </a>
                                    <a href="" className="header__social_link social_link"> <img
                                        src={isDark ? "img/footer-w/social/tg.svg" : "img/footer-d/social/tg.svg"}
                                        alt="tg" className="social_img" /> </a>
                                    <a href="" className="header__social_link social_link"> <img
                                        src={isDark ? "img/footer-w/social/tw.svg" : "img/footer-d/social/tw.svg"}
                                        alt="tw" className="social_img" /> </a>
                                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  };
}
