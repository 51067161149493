import {Component, default as React} from "react";
import ExchangeSummaryDetails from "./ExchangeSummaryDetails";
import {ExchangeSession} from "../../models/ExchangeSession";
import {useHistory} from "react-router-dom";
import {ExchangeStepsLine} from "./ExchangeStepsLine";
import {Timer} from "../common/Timer";
import {ExTransactionStatus} from "../../models/types/ExTransactionStatus";
import {ApiData} from "../../models/ApiData";
import {ResultInfo} from "./ResultInfo";
import {UrlUtils} from "../../utils/UrlUtils";
import {StringUtils} from "../../utils/StringUtils";
import {Exchange} from "../../models/Exchange";

export class ExchangeStep5StatusContent extends Component {
    state = {
        fiatCurrency: "EUR",
        cryptoCurrency: "BTC",
        crypto_amount: 0,
        fiat_amount: 0,
        wallet: '',
        payment_token: "",
        id: "",
        status_id: ExTransactionStatus.STATUS_PENDING,
        status: "",
        info: "",
        ex_transaction_id: false
    }

    _isProcessing = false;

    public componentDidMount(): void {
        ExchangeSession.redirectIfWrongStep("5");

        const state = ExchangeSession.getExchangeState();
        const session_data = ExchangeSession.data;

        if(state) {
            if(session_data['payment_token'] && session_data['ex_transaction_id'])
            {
                state['payment_token'] = session_data['payment_token'];
                state['ex_transaction_id'] = session_data['ex_transaction_id'];
            }
           else if(session_data['status_info'])
            {
                state['info'] = session_data['status_info'];
                state['status_id'] = ExTransactionStatus.STATUS_ERROR;
                state['status'] = "Error";
                delete state['ex_transaction_id'];
            }
           else this.clearSession();

           this.setState(state);
        }
        else
            this.clearSession();
    }

    clearSession = () =>
    {
        ExchangeSession.data = null;
        const history = useHistory();
        history.goBack();
    }

    public componentDidUpdate(prevProps: any, prevState: any): void
    {
        if(!prevState.payment_token)
            this.onTimerUpdate();
    }

    onTimerUpdate = () =>
    {
        if(!StringUtils.isEmpty(this.state.payment_token)) {
            ApiData.exchangeApiRequest({payment_token: this.state.payment_token}, 'get_status')
                .then((result) => {
                    console.log("onTimer", result);
                    if (result.success) {
                        this.setState(
                            {
                                id: result.data['id'],
                                status_id: parseInt(result.data['status_id']),
                                status: result.data['status'],
                                info: result.data['info'],
                            }
                        )
                    }
                });
        }
    }

    onTryAgain = async () =>
    {
        if(this._isProcessing)
            return ;
        this._isProcessing = true;
        console.log("AGAIN")
        await ExchangeSession.updateCryptoInput();

        const session_data = ExchangeSession.data;
        if(session_data['wallet']){

            const result = await Exchange.create(true);

            if (result && result.success) {
                ExchangeSession.saveDataParam("step", "4.2");
                UrlUtils.redirectTo("exchange?step=4.2");
                return;
            }
        }

        ExchangeSession.cancel();
        UrlUtils.redirectTo("exchange");
    }

    onBuyAgain = async () =>
    {
        if(this._isProcessing)
            return ;
        this._isProcessing = true;
        console.log("Buy AGAIN")

        ExchangeSession.cancel();
        UrlUtils.redirectTo("exchange");
    }

    renderStatus = () =>
    {
        let timer = <></>;
        if(this.state.status_id != ExTransactionStatus.FUNDS_SENT)
            timer = <Timer count_down={7} text={"The status will be updated in"} onUpdate={this.onTimerUpdate} className={"steps__informations_time-form__text_time"}/>;
        const isSuccess = this.state.status_id != ExTransactionStatus.STATUS_ERROR;

        if(this.state.status)
            switch (this.state.status_id) {
                case ExTransactionStatus.STATUS_ERROR:
                    return  <>
                                <ResultInfo id={this.state.ex_transaction_id} message_head={this.state.status} message={this.state.info} success={isSuccess}/>
                                <br />
                                <div className="text-center">
                                {timer}
                                </div>
                                <button className="button button_bg_green steps__later center" onClick={this.onTryAgain} >Try again</button>
                            </>;
                default:
                    return  <>
                        <ResultInfo id={this.state.ex_transaction_id} message_head={this.state.status} message={this.state.info} success={isSuccess}/>
                        <br />
                        <div className="text-center">
                            {timer}
                        </div>
                        <button className="button button_bg_green steps__later center" onClick={this.onBuyAgain} >Buy coins again</button>
                    </>;
            }
    }

    render = () => (
        <div className="body__steps">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 steps__dots_bg">
                        <ExchangeStepsLine step_id={4} />
                    </div>
                    <div className="col-lg-10">
                        <div className="steps__box steps__box_boxing">
                            <div className="row no-gutters">
                                <div className="steps__part_big">
                                    <div className="steps__box_light">
                                        <h3 className="steps__box_title">Current status of your exchange</h3>

                                        {this.renderStatus()}

                                    </div>
                                </div>
                                <div className="steps__part_small">
                                    <div className="steps__box_dark">
                                        <ExchangeSummaryDetails ex_transaction_id={this.state.ex_transaction_id} payment_token={this.state.payment_token} wallet={this.state.wallet} fiat_amount={this.state.fiat_amount} is_status_page={true} crypto_currency={this.state.cryptoCurrency} fiat_currency={this.state.fiatCurrency} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
