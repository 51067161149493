const DefaultValuesDEV = {
    HOME: "https://dev.gempal.exchange/",
    CLIENT: "https://dev.gempal.exchange/client/",
    PAY: "https://devpay.gempal.exchange/",
    SECURE: "https://devsecure.gempal.exchange/"
};

const DefaultValuesProd = {
    HOME: "https://gempal.exchange/",
    CLIENT: "https://gempal.exchange/client/",
    PAY: "https://pay.gempal.exchange/",
    SECURE: "https://secure.gempal.exchange/"
};

export var DefaultValues = DefaultValuesProd;

export function initVars(is_dev: boolean) {
    if (is_dev) {
        DefaultValues = DefaultValuesDEV;
    }
}
