import * as React from "react";
import useForm from 'react-hook-form'

import {useHistory} from "react-router-dom";

interface IRecoveryNewPasswordForm {
    onSubmit: (data: any) => void
}

export default function RecoveryNewPasswordForm(props: IRecoveryNewPasswordForm) {
    const { register, handleSubmit, watch, errors, getValues } = useForm();
console.log(errors);

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <div className="steps__form">
                <div className="steps__form_boxing">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form__item">
                                <input name="password" type="password" className="input input_small" placeholder="New password" ref={register({ required: true, minLength: 6 })} />
                                {errors.password && <span className="erorrs">Please, enter a strong password!</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form__item">
                                <input name={"password2"} type="password" className="input input_small" placeholder="Repeat new password" ref={register({ required: true,  validate: (value) => {
                                        return value === watch('password'); // value is from password2 and watch will return value from password1
                                    } })} />
                                {errors.password2 && <span className="erorrs">Passwords didn't match!</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="steps__form_footer">
                            <button type="submit" className="button button_bg_red steps-form__submit">Restore!</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}