import * as React from "react";

export interface FooterProps {
  isDark: boolean;
}

export class Footer extends React.Component<FooterProps, {}> {
  constructor(props: FooterProps) {
    super(props);
  }

  render = () => (
    <>
      <footer className={this.props.isDark ? "footer light" : "footer dark"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <hr className="footer__hr" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="footer__item">
                <h3 className="footer__title">
                  <a className="navbar-brand navbar-brand_light" href="/">
                    <img
                      src={
                        this.props.isDark
                          ? "img/logo_white.png"
                          : "img/logo_black.png"
                      }
                      alt="logo"
                      className="navbar-brand_header"
                    />
                  </a>
                </h3>
                <p className="footer__lisence" style={{ marginBottom: 0 }}>
                  Pals Technology SP. Z O.O, Reg Number 0001099408, 
                </p>

                <p style={{ paddingTop: 0 }}>
                  Legal Address: Piotrkowska, 116-52, Lodz, 90-006, Poland
                </p>
                <div className="footer__cards d-flex align-items-center">
                  <span>Payment with</span>
                  <img
                    src="img/footer-w/payment/visa.png"
                    alt="visa"
                    className="footer__card"
                  />
                  <img
                    src="img/footer-w/payment/mastercard.svg"
                    alt="mastercard"
                    className="footer__card"
                  />
                  <img
                    src="img/3d.png"
                    alt="3d"
                    className="footer__card footer__secure_3d"
                  />
                </div>
                <div className="footer__dss">
                  <img src="img/pcidss.png" alt="pcidss" />
                </div>
                <div className="footer__copy d-lg-block d-none">
                  <p className="footer__address">
                    {" "}
                    Website is owned by Pals Technology SP. Z O.O, Reg Number 0001099408, Piotrkowska,
                    116-52, Lodz, 90-006, Poland
                  </p>
                  <p className="footer__copyright">
                    {new Date().getFullYear()} © Gempal. All Rights Reserved.
                    Gempal does not operate in Germany and in the United States
                    due to the internal Company Policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row flex-nowrap">
                <div className="col-lg-6">
                  <div className="footer__item">
                    <h3 className="footer__title">Platform</h3>
                    <ul className="list footer__list">
                      <li className="footer__list_item">
                        <a href="exchange" className="footer__list_link">
                          Buy Crypto
                        </a>
                      </li>

                      {/* <li className="footer__list_item"><a href="cards"
                                                                                 className="footer__list_link">Prepaid
                                                Card</a>
                                            </li> */}
                      {/*<li className="footer__list_item"><a href="affiliate"*/}
                      {/*                                     className="footer__list_link">Affiliate*/}
                      {/*    Program</a></li>*/}
                      <li className="footer__list_item">
                        <a href="rates" className="footer__list_link">
                          Cryptocurrency Rates
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__item">
                    <h3 className="footer__title">Company</h3>
                    <ul className="list footer__list">
                      <li className="footer__list_item">
                        <a href="about" className="footer__list_link">
                          About Us
                        </a>
                      </li>
                      <li className="footer__list_item">
                        <a href="reviews.html" className="footer__list_link">
                          Reviews
                        </a>
                      </li>
                      <li className="footer__list_item">
                        <a href="contact_us" className="footer__list_link">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="footer__item">
                    <h3 className="footer__title">Legal</h3>
                    <ul className="list footer__list">
                      <li className="footer__list_item">
                        <a
                          href="terms_of_use.html"
                          className="footer__list_link"
                        >
                          Legal
                        </a>
                      </li>
                      {/* <li className="footer__list_item"><a href="license.html"
                                                                                 className="footer__list_link">License</a>
                                            </li> */}
                      <li className="footer__list_item">
                        <a
                          href="risk_disclosure.html"
                          className="footer__list_link"
                        >
                          Risk Disclosure
                        </a>
                      </li>
                      <li className="footer__list_item">
                        <a href="avoid-fraud" className="footer__list_link">
                          Avoid Fraud
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__item">
                    <h3 className="footer__title footer__title_padding">
                      Help
                    </h3>
                    <ul className="list footer__list">
                      <li className="footer__list_item">
                        <a href="faq.html" className="footer__list_link">
                          FAQ
                        </a>
                      </li>
                      {/* <li className="footer__list_item"><a href="tariffs" className="footer__list_link">Tariffs</a></li> */}
                      {/* <li className="footer__list_item"><a href="available"
                                                                                 className="footer__list_link">Supported
                                                Countries</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex flex-wrap">
              <div className="footer__item footer__item_info">
                <img
                  src="img/footer-w/flag/01.svg"
                  alt="flag"
                  className="footer__flag"
                />
                <span className="footer__text footer__tel">
                  +48 838 88 13 58
                </span>
                <span className="footer__text footer__tel">
                  Mon - Fri: GMT 8AM - 8PM
                </span>
              </div>
            </div>
            <div className="col-lg-1">
              {/* <div
                                className="footer__social d-flex flex-lg-column flex-row align-items-md-end align-items-start">
                                <a href="" className="footer__social_link social_link"> <img
                                    src={this.props.isDark ? "img/footer-w/social/fb.svg" : "img/footer-d/social/fb.svg"}
                                    alt="fb" className="social_img"/>
                                </a>
                                <a href="" className="footer__social_link social_link"> <img
                                    src={this.props.isDark ? "img/footer-w/social/tg.svg" : "img/footer-d/social/tg.svg"}
                                    alt="tg" className="social_img"/>
                                </a>
                                <a href="" className="footer__social_link social_link"> <img
                                    src={this.props.isDark ? "img/footer-w/social/tw.svg" : "img/footer-d/social/tw.svg"}
                                    alt="tw" className="social_img"/>
                                </a>
                            </div> */}
              <div className="footer__copy d-lg-none d-block">
                <p className="footer__address">
                  {" "}
                  Website is owned by Pals Technology SP. Z O.O, Reg Number 0001099408, Piotrkowska,
                  116-52, Lodz, 90-006, Poland
                </p>
                <p className="footer__copyright">
                  {new Date().getFullYear()} © Gempal. All Rights Reserved.
                  Gempal does not operate in Germany and in the United States
                  due to the internal Company Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
