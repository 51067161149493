import * as React from "react";
import useForm from "react-hook-form";
import { ExceptionCodes, getApiDataPromise } from "../../models/ApiData";
import { ExClient } from "../../models/ExClient";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { VALID_NAME, VALID_EMAIL, VALID_PHONE } from "../../constants";

export default function ContactForm() {
  const { register, handleSubmit, watch, errors } = useForm();
  let in_progress = false;

  const onSubmit = async function (data: any) {
    console.log(data);
    if (in_progress) return;

    in_progress = true;

    data["g-recaptcha-response"] = $('[name="g-recaptcha-response"]').val();
    if (!data["g-recaptcha-response"]) {
      toast.error("Please fill out the reCAPTCHA!");
      return;
    }

    var result = await getApiDataPromise(data, "public_data", "send_ticket");

    if (result.success) {
      toast.success("Successful!");
      $("#message_form").hide();
      $("#success_message").show();
    } else {
      toast.error(result.message);
      in_progress = false;
    }
  };
  return (
    <>
      <form id="message_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="steps__form">
          <div className="steps__form_boxing">
            <div className="row">
              <div className="col-lg-6 padding_right_30">
                <div className="form__item">
                  <input
                    name="name"
                    type="text"
                    className="input input_small"
                    placeholder="Name"
                    ref={register({ required: true, ...VALID_NAME })}
                  />
                  {errors.name && (
                    <span className="erorrs">
                      {errors.name?.message || "This field is required"}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-6 padding_left_30">
                <div className="form__item">
                  <input
                    name="email"
                    type="text"
                    className="input input_small"
                    placeholder="Email"
                    id="email"
                    ref={register({ required: true, ...VALID_EMAIL })}
                  />
                  {errors.email && (
                    <span className="erorrs">{errors.email?.message}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 padding_right_30">
                <div className="form__item">
                  <input
                    name="phone"
                    type="text"
                    className="input input_small"
                    placeholder="Phone"
                    ref={register({ required: true, ...VALID_PHONE })}
                  />
                  {errors.phone && (
                    <span className="erorrs">
                      {errors.phone?.message || "This field is required"}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-6 padding_left_30">
                <div className="form__item">
                  <input
                    name="subject"
                    type="text"
                    className="input input_small"
                    placeholder="Subject"
                    ref={register({ required: true, minLength: 5 })}
                  />
                  {errors.subject && (
                    <span className="erorrs">Please describe your topic</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 padding_right_30">
                <div className="form__item form__item_last">
                  <textarea
                    name="message"
                    className="input input_small"
                    placeholder="Message"
                    ref={register({ required: true, minLength: 20 })}
                  ></textarea>
                  {errors.message && (
                    <span className="erorrs">
                      Please write a more detailed message
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="recapcha">
                <ReCAPTCHA sitekey="6Lct3RgqAAAAAKbFasPzUhv935OyPnZJ0F8KkstV" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="steps__form_footer">
                <button
                  type="submit"
                  className="button button_bg_red steps-form__submit"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        id="success_message"
        style={{ display: "none" }}
        className="text-center"
      >
        <img src="img/available.svg" alt="img" />
        <h1 className="text-success text-center">
          We will be contacting you soon.
        </h1>
      </div>
    </>
  );
}
