import { FC, ReactNode, useRef, useState } from "react";
import { arrow, autoPlacement, FloatingArrow, offset, shift, useDismiss, useFloating, useInteractions } from "@floating-ui/react";
import React from "react";

type HelpNoteProps = {
  children: ReactNode;
  icon: ReactNode;
  className?: string;
};

export const HelpNote: FC<HelpNoteProps> = ({ className, children, icon }: HelpNoteProps) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<SVGSVGElement | null>(null);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: "right-start",
    middleware: [
      autoPlacement({}),
      offset(10),
      shift({ padding: 10 }),
      arrow({
        element: arrowRef,
        padding: 10,
      }),
    ],
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()} onClick={() => setOpen(!open)} className={className}>
        {icon}
      </span>
      {open && (
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            maxWidth: 300,
            zIndex: 9999,
            backgroundColor: "white",
            boxShadow: "0 10px 70px 0 rgba(196, 196, 208, 0.45)",
            fontSize: 12,
          }}
          {...getFloatingProps()}
        >
          <FloatingArrow ref={arrowRef} context={context} className="text-white" fill="white" startOffset={10} />

          <div style={{ padding: 10, lineHeight: "normal" }}>
            <h6>Note</h6>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
