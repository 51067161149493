import React from "react";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { ApiData, ExceptionCodes, getApiDataPromise } from "../../models/ApiData";
import { EmailVerifyModal } from "../login/EmailVerifyModal";
import OptionsApiData from "../common/OptionsApiData";
import { ExClient } from "../../models/ExClient";
import { ExchangeSession } from "../../models/ExchangeSession";
import ReCAPTCHA from "react-google-recaptcha";
import { TermsAndConditionsLabel } from "../exchange/TermsAndConditionsLabel";

export default function SignUpForm() {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  console.log("errors", errors);
  const onSubmit = async function (data: any) {
    data["g-recaptcha-response"] = $('[name="g-recaptcha-response"]').val();

    if (!data["g-recaptcha-response"]) {
      toast.error("Please fill out the reCAPTCHA!");
      return;
    }

    if (data.password !== data.password2) {
      toast.error("Your password and confirmation password do not match!");
      return;
    }

    data.shop_id = ExClient.shop_id;

    //this.props.history.push('/main');
    var result = await getApiDataPromise(data, "client", "register");

    if (result.success) {
      toast.success("Registered!");
      await ExClient.sendRegEvent("sign_up");
      ExchangeSession.email_temp = data.email;

      history.push("/login?email=" + data.email + "#registered");
    } else {
      toast.error(result.message);

      if (result.code === ExceptionCodes.CAPTCHA_CODE) {
        // @ts-ignore
        grecaptcha.reset();
      }
    }
  };

  const onCountriesLoaded = () => {
    ApiData.clientApiRequest({}, "get_country").then((data) => {
      if (data.success) $("#country_code").val(data.data["code"]);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="steps__form">
          <div className="steps__form_boxing">
            <div className="register_form">
              <>
                <>
                  <div className="form__item">
                    <input
                      name="first_name"
                      type="text"
                      className="input input_small"
                      placeholder="First Name"
                      ref={register({
                        required: true,
                        minLength: 2,
                        pattern: /^[a-zA-Z\s]*$/,
                      })}
                    />
                    {errors.first_name &&
                      (errors.first_name.type === "pattern" ? (
                        <span className="erorrs">This field should be in Latin letters!</span>
                      ) : (
                        <span className="erorrs">This field is required!</span>
                      ))}
                  </div>
                </>
                <>
                  <div className="form__item">
                    <span style={{ position: "absolute", marginTop: -16, fontSize: 12, opacity: 0.5 }}>Nationality</span>
                    <select
                      id="country_code"
                      name="national"
                      className="input select_light input_small "
                      ref={register({ required: true })}
                    >
                      <OptionsApiData type={"country"} key_equals_value={false} onLoaded={onCountriesLoaded} />
                    </select>
                    {errors.country && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>
              <>
                <>
                  <div className="form__item">
                    <input
                      name="last_name"
                      type="text"
                      className="input input_small"
                      placeholder="Last Name"
                      ref={register({
                        required: true,
                        minLength: 2,
                        pattern: /^[a-zA-Z\s]*$/,
                      })}
                    />
                    {errors.last_name &&
                      (errors.last_name.type === "pattern" ? (
                        <span className="erorrs">`This field should be in Latin letters!`</span>
                      ) : (
                        <span className="erorrs">This field is required!</span>
                      ))}
                  </div>
                </>

                <>
                  <div className="form__item">
                    <span style={{ position: "absolute", marginTop: -16, fontSize: 12, opacity: 0.5 }}>Country of Residence</span>
                    <select
                      id="country_code"
                      name="country_code"
                      className="input select_light input_small "
                      ref={register({ required: true })}
                    >
                      <OptionsApiData type={"country"} key_equals_value={false} onLoaded={onCountriesLoaded} />
                    </select>
                    {errors.country && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>

              <>
                <>
                  {/* <div className="form__item">
                                    <input name="date_birth" type="text" className="input input_small datepicker-date" placeholder="Date of Birth" ref={register({ required: true })} />
                                    {errors.date_birth && <span className="erorrs">This field is required!</span>}
                                </div> */}
                  <div className="form__item">
                    <input
                      name="email"
                      id={"email"}
                      type="text"
                      className={"input input_small" + (errors.email ? "  field-erorrs" : "")}
                      placeholder="Email"
                      ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                    />
                    {errors.email && <span className="erorrs">Please enter a valid email!</span>}
                  </div>
                  {/* <div className="form__item">
                  <select id="country_code" name="country_code" className="input input_small" ref={register({ required: true })}>
                    <OptionsApiData type={"country"} key_equals_value={false} onLoaded={onCountriesLoaded} />
                  </select>

                  {errors.country && <span className="erorrs">This field is required!</span>}
                </div> */}
                </>
                <>
                  <div className="form__item">
                    <input
                      name="state"
                      type="text"
                      className="input input_small"
                      placeholder="State"
                      ref={register({ required: true, minLength: 2 })}
                    />
                    {errors.state && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>
              <>
                <>
                  <div className="form__item">
                    <input
                      name="phone"
                      type="tel"
                      className="input input_small"
                      placeholder="Phone"
                      ref={register({
                        required: true,
                        pattern: /^[0-9+]{1,}[0-9-]{5,15}$/,
                      })}
                    />
                    {errors.phone && <span className="erorrs">Please enter a valid phone!</span>}
                  </div>
                </>
                <>
                  <div className="form__item">
                    <input
                      name="city"
                      type="text"
                      className="input input_small"
                      placeholder="City"
                      ref={register({ required: true, minLength: 2 })}
                    />
                    {errors.city && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>
              <>
                <>
                  <div className="form__item">
                    <input
                      name="password"
                      type="password"
                      className="input input_small"
                      placeholder="Password"
                      ref={register({ required: true, minLength: 6 })}
                    />
                    <img src="img/see.svg" alt="see" className="in_password" />
                    {errors.password && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
                <>
                  <div className="form__item form__item_nolabel">
                    <input
                      name="address"
                      type="text"
                      className="input input_small"
                      placeholder="Street Address"
                      ref={register({ required: true, minLength: 2 })}
                    />
                    {errors.address && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>
              <>
                <>
                  <div className="form__item">
                    <input
                      name="password2"
                      type="password"
                      className="input input_small"
                      placeholder="Repeat Password"
                      ref={register({ required: true, minLength: 6 })}
                    />
                    <img src="img/see.svg" alt="see" className="in_password" />
                    {errors.password2 && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
                <>
                  <div className="form__item form__item_nolabel">
                    <input
                      name="address2"
                      type="text"
                      className="input input_small"
                      placeholder="Apt/Suite"
                      ref={register({ required: false, minLength: 2 })}
                    />
                    {errors.address2 && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>
              <>
                <>
                  <div className="steps-form__control steps-form__control_sex">
                    <label className="steps-form__labels labels">Sex</label>
                    <div className="steps-form__control_wrap d-flex">
                      <div className="custom-control custom-radio">
                        <input
                          name="sex"
                          value="1"
                          type="radio"
                          id="Male"
                          className="custom-control-input"
                          defaultChecked
                          ref={register({ required: true })}
                        />
                        <label className="custom-control-label" htmlFor="Male">
                          Male
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          name="sex"
                          value="0"
                          type="radio"
                          id="Female"
                          className="custom-control-input"
                          ref={register({ required: true })}
                        />
                        <label className="custom-control-label" htmlFor="Female">
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                </>
                <>
                  <div className="form__item form__item_nolabel">
                    <input
                      name="zip"
                      type="text"
                      className="input input_small"
                      placeholder="Zip Code"
                      ref={register({ required: true, minLength: 2 })}
                    />
                    {errors.zip && <span className="erorrs">This field is required!</span>}
                  </div>
                </>
              </>
            </div>
            <>
              <>
                <div className="step__box_agree">
                  <div className="custom-control custom-checkbox">
                    <input
                      name="agree_terms"
                      type="checkbox"
                      className="custom-control-input"
                      id="agree"
                      defaultChecked={false}
                      ref={register({ required: true })}
                    />
                    <TermsAndConditionsLabel />
                    {errors.agree_terms && <span className="erorrs ml-five">Please accept Terms &amp; Conditions!</span>}
                  </div>
                </div>
                <div className="step__box_agree">
                  <div className="custom-control custom-checkbox">
                    <input
                      name="agree_risk"
                      id="agree_risk"
                      type="checkbox"
                      className="custom-control-input"
                      ref={register({ required: true })}
                    />
                    <label className="custom-control-label" htmlFor="agree_risk">
                      I accept{" "}
                      <a href="risk_disclosure.html" target="_blank">
                        Risk disclosure
                      </a>
                    </label>
                    {errors.agree_risk && <span className="erorrs ml-five">Please accept Risk disclosure!</span>}
                  </div>
                </div>
                <div className="step__box_agree">
                  <div className="custom-control custom-checkbox">
                    <input
                      name="agree_policy"
                      id="agree_policy"
                      type="checkbox"
                      className="custom-control-input"
                      defaultChecked={false}
                      ref={register({ required: true })}
                    />
                    <label className="custom-control-label" htmlFor="agree_policy">
                      I accept{" "}
                      <a href="refund_cancellation_policy.html" target="_blank">
                        Refund and Cancellation policy
                      </a>
                    </label>
                    {errors.agree_policy && <span className="erorrs ml-five">Please accept Refund and Cancellation policy!</span>}
                  </div>
                </div>
                <div className="step__box_agree">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="agree_age"
                      id="old"
                      defaultChecked={false}
                      ref={register({ required: true })}
                    />
                    <label className="custom-control-label" htmlFor="old">
                      I confirm that I am 18 years old
                    </label>
                    {errors.agree_age && <span className="erorrs ml-five">Please accept that you are over 18 years old!</span>}
                  </div>
                </div>
              </>
            </>
            <div className="row">
              <div className="col-lg-12">
                <ReCAPTCHA sitekey="6Lct3RgqAAAAAKbFasPzUhv935OyPnZJ0F8KkstV" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="steps__form_footer">
                <button type="submit" className="button button_bg_red steps-form__submit">
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <EmailVerifyModal />
    </>
  );
}
