import * as React from "react";

export const RatesCommissionContent = () => {
  return (
    <section className="commission">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="title commission__title">
              The lowest possible commission{" "}
            </h2>
            <p className="slogan commission__slogan">
              Gempal is a simple way to exchange your crypto on the best <br />{" "}
              possible conditions
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 commission__m">
            <div className="commission__box">
              <h3 className="commission__caption color_1">Totally Automated</h3>
              <p className="commission__text">
                Gempal is totally automated complying with the most up-to-date
                IT technologies
              </p>
            </div>
          </div>
          <div className="col-lg-3 commission__m">
            <div className="commission__box">
              <h3 className="commission__caption color_2">Anywhere, Anytime</h3>
              <p className="commission__text">
                After the registration you become the partner of the Affiliate
                Program
              </p>
            </div>
          </div>
          <div className="col-lg-3 commission__m">
            <div className="commission__box active">
              <h3 className="commission__caption color_3">Affiliate program</h3>
              <p className="commission__text">
                Use service anytime without restrictions 24/7, 365 days a year
              </p>
            </div>
          </div>
          <div className="col-lg-3 commission__m">
            <div className="commission__box">
              <h3 className="commission__caption color_1">Discount System</h3>
              <p className="commission__text">
                Our flexible cumulative quantity discount system works for all
                registered users
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <a href="sign_up" className="button button_bg_red commission_butt">
              Register
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
